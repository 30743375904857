import { InputHandler } from '../../components/ui/Input/useInput';
import { Strings } from '../constants/Strings';

const countMatchingRules = (password: string) => {
	let countMatchRules = 0;
	if (password.match(/[a-z]/)) countMatchRules++;
	if (password.match(/[A-Z]/)) countMatchRules++;
	if (password.match(/[0-9]/)) countMatchRules++;
	if (password.match(/[^a-zA-Z0-9]/)) countMatchRules++;
	return countMatchRules;
};

export const validatePassword = (password: string, handler: InputHandler<string>) => {
	if (password.length === 0) {
		handler.setSuccess('');
		return true;
	}
	if (password.length < 8) {
		handler.setError(Strings.errors.general.incorrectFormatting);
		return false;
	}

	let countMatchRules = countMatchingRules(password);
	if (countMatchRules < 3) {
		handler.setError(Strings.errors.general.incorrectFormatting);
		return false;
	}
	handler.setSuccess(Strings.success.greatLetsContinue);
};
