import { useEffect, useState } from 'react';

type CallbackFunction = (() => void) | undefined;

interface InputState<T = undefined> {
	value: T;
	error: string;
	success: string;
	loading: boolean;
	disabled: boolean;
	label: string;
	placeholder?: string;
	maxlength?: number;
	// charCounter?: boolean;
	required?: boolean;
	onChange: ((value: string) => void) | undefined;
	onFocus: CallbackFunction;
	onBlur: CallbackFunction;
	icon?: JSX.Element;
	mask?: string;
	id?: string;
}

interface InputSetters<T> {
	// setValue: (value: string) => InputSetters;
	// setError: (error: string) => InputSetters;

	setValue: (value: T) => InputSetters<T>;
	setLoading: (loading: boolean) => InputSetters<T>;
	setDisabled: (disabled: boolean) => InputSetters<T>;
	setError: (error: string) => InputSetters<T>;
	setSuccess: (success: string) => InputSetters<T>;
	setLabel: (label: string) => InputSetters<T>;
	setPlaceholder: (placeholder: string) => InputSetters<T>;
	setMaxlength: (maxlength: number | undefined) => InputSetters<T>;
	// setCharCounter: (charCounter: boolean) => InputSetters<T>;
	setRequired: (required: boolean) => InputSetters<T>;
	setOnChange: (onChange: ((value: string) => void) | undefined) => InputSetters<T>;
	setOnFocus: (onFocus: CallbackFunction) => InputSetters<T>;
	setOnBlur: (onBlur: CallbackFunction) => InputSetters<T>;
	setIcon: (icon: JSX.Element) => InputSetters<T>;
	setMask: (mask: string) => InputSetters<T>;
	setId: (id: string) => InputSetters<T>;
}

export interface InputHandler<T = undefined> extends InputState<T>, InputSetters<T> {}

// export const useInput = (initialValue: string): InputHandler => {
// 	const [value, _setValue] = useState<string>(initialValue);
// 	const [error, _setError] = useState<string>('');

// 	const setValue = (value: string): InputSetters => {
// 		_setValue(value);
// 		return setters;
// 	};

// 	const setError = (error: string) => {
// 		_setError(error);
// 		return setters;
// 	};

// 	const setters: InputSetters = {
// 		setValue,
// 		setError,
// 	};

// 	return {
// 		value,
// 		setValue,
// 		error,
// 		setError,
// 	};
// };

export function useInput<T>(initialValue: T, initialState?: Partial<InputState<T>>): InputHandler<T>;
export function useInput<T = undefined>(): InputHandler<T | undefined>;
export function useInput<T = undefined>(first: any = undefined, second: any = undefined): InputHandler<T> {
	const [value, _setValue] = useState<T>(first ?? undefined);
	const [loading, _setLoading] = useState<boolean>(second?.loading ?? false);
	const [disabled, _setDisabled] = useState<boolean>(second?.disabled ?? false);
	const [error, _setError] = useState<string>(second?.error ?? '');
	const [success, _setSuccess] = useState<string>(second?.success ?? '');
	const [label, _setLabel] = useState<string>(second?.label ?? '');
	const [placeholder, _setPlaceholder] = useState<string | undefined>(second?.placeholder ?? undefined);
	const [maxlength, _setMaxlength] = useState<number | undefined>(second?.maxlength ?? undefined);
	// const [charCounter, _setCharCounter] = useState<boolean>(second?.charCounter ?? false);
	const [required, _setRequired] = useState<boolean>(second?.required ?? false);
	const [onChange, _setOnChange] = useState<((value: string) => void) | undefined>(
		second?.onChange ? () => second.onChange : undefined
	);
	const [onFocus, _setOnFocus] = useState<CallbackFunction>(second?.onFocus ? () => second.onFocus : undefined);
	const [onBlur, _setOnBlur] = useState<CallbackFunction>(second?.onBlur ? () => second.onBlur : undefined);
	const [icon, _setIcon] = useState<JSX.Element>();
	const [mask, _setMask] = useState<string>(second?.mask ?? '');
	const [id, _setId] = useState<string>(second?.id ?? '');

	useEffect(() => {
		setOnChange(second?.onChange ? () => second.onChange : undefined);
		setOnBlur(second?.onBlur ? () => second.onBlur : undefined);
	}, [value]);

	const setValue = (value: T): InputSetters<T> => {
		_setValue(value);
		return setters;
	};

	const setLoading = (loading: boolean): InputSetters<T> => {
		_setLoading(loading);
		return setters;
	};

	const setDisabled = (disabled: boolean): InputSetters<T> => {
		_setDisabled(disabled);
		return setters;
	};

	const setError = (error: string): InputSetters<T> => {
		_setError(error);
		_setSuccess('');
		return setters;
	};

	const setSuccess = (success: string): InputSetters<T> => {
		_setSuccess(success);
		_setError('');
		return setters;
	};

	const setLabel = (label: string): InputSetters<T> => {
		_setLabel(label);
		return setters;
	};

	const setPlaceholder = (placeholder: string | undefined): InputSetters<T> => {
		_setPlaceholder(placeholder);
		return setters;
	};

	const setMaxlength = (maxlength: number | undefined): InputSetters<T> => {
		_setMaxlength(maxlength);
		return setters;
	};

	// const setCharCounter = (charCounter: boolean): InputSetters<T> => {
	// 	_setCharCounter(charCounter);
	// 	return setters;
	// };

	const setRequired = (required: boolean): InputSetters<T> => {
		_setRequired(required);
		return setters;
	};

	const setOnChange = (onChange: ((value: string) => void) | undefined): InputSetters<T> => {
		_setOnChange(onChange);
		return setters;
	};

	const setOnFocus = (onFocus: CallbackFunction): InputSetters<T> => {
		_setOnFocus(onFocus);
		return setters;
	};

	const setOnBlur = (onBlur: CallbackFunction): InputSetters<T> => {
		_setOnBlur(onBlur);
		return setters;
	};

	const setIcon = (icon: JSX.Element): InputSetters<T> => {
		_setIcon(icon);
		return setters;
	};

	const setMask = (mask: string): InputSetters<T> => {
		_setMask(mask);
		return setters;
	};

	const setId = (id: string): InputSetters<T> => {
		_setId(id);
		return setters;
	};

	const setters: InputSetters<T> = {
		setValue,
		setLoading,
		setDisabled,
		setError,
		setSuccess,
		setLabel,
		setPlaceholder,
		setMaxlength,
		// setCharCounter,
		setRequired,
		setOnChange,
		setOnFocus,
		setOnBlur,
		setIcon,
		setMask,
		setId,
	};

	return {
		value,
		loading,
		disabled,
		error,
		success,
		label,
		placeholder,
		maxlength,
		// charCounter,
		required,
		onChange,
		onFocus,
		onBlur,
		icon,
		mask,
		id,
		...setters,
	};
}
