import { FC, ChangeEvent, KeyboardEvent, HTMLProps } from 'react';
import { Icon } from '../Icon';
import { InputHandler } from './useInput';
import styles from './Input.module.scss';
import { Tooltip } from '../Tooltip/Tooltip';
import { formatMoney, applyMask, validateBirthDate, validateName, validateZipCode, getInputType } from './InputUtils';
import { validatePhone } from '../../../shared/utils/PhoneValidation';
import { validateEmail } from '../../../shared/utils/EmailValidation';
import { validatePassword } from '../../../shared/utils/PasswordValidation';
import { Strings } from '../../../shared/constants/Strings';

export enum InputModes {
	Text = 'text',
	Decimal = 'decimal',
	Numeric = 'numeric',
	Tel = 'tel',
	Search = 'search',
	Email = 'email',
	Money = 'money',
	Url = 'url',
	None = 'none',
	Password = 'password',
	ConfirmPassword = 'confirm password',
	ZipCode = 'zip code',
	BirthDate = 'birth date',	
	Name = 'name',
}

export interface InputProps extends Omit<HTMLProps<HTMLInputElement>, 'value'> {
	handler: InputHandler<string>;
	mode?: InputModes;
	renderBottomRight?: () => JSX.Element;
	onEnter?: () => void;
	helperText?: string;
	icon?: JSX.Element;
	tooltip?: string | JSX.Element;
	forceShowSideTooltip?: 'left' | 'right';
}

export const Input: FC<InputProps> = (props) => {
	const { handler, renderBottomRight, mode, onEnter, helperText, icon, tooltip, forceShowSideTooltip, ...rest } =
		props;

	const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
		if (handler.loading) return;
		handler.onChange?.(ev.currentTarget.value);
		handler.setError('');

		let val = ev.currentTarget.value;
		switch (mode) {
			case InputModes.Numeric:
				val = val.replace(/[^0-9]/g, '');
				handler.setSuccess(Strings.success.greatLetsContinue);
				break;
			case InputModes.Text:
				handler.setSuccess(Strings.success.greatLetsContinue);
				break;
			case InputModes.Tel:
				val = val.replace(/[^0-9]/g, '');
				validatePhone(val, handler);
				break;
			case InputModes.Money:
				val = formatMoney(val);
				handler.setSuccess(Strings.success.greatLetsContinue);
				break;
			case InputModes.Email:
				validateEmail(val, handler);
				break;
			case InputModes.Password:
				validatePassword(val, handler);
				break;
			case InputModes.ZipCode:
				val = val.replace(/[^0-9]/g, '');
				validateZipCode(val, handler);
				break;
			case InputModes.BirthDate:
				val = val.replace(/[^0-9]/g, '');
				validateBirthDate(val, handler);
				break;
			case InputModes.Name:
				validateName(val, handler);
			default:
				break;
		}
		handler.setValue(val);
	};

	const handleKeyDown = (ev: KeyboardEvent) => {
		if (ev.key === 'Enter') {
			ev.preventDefault();
			onEnter?.();
			return;
		}
	};

	const containerClass = `
	${styles['container']}
	${!!handler.error ? styles['has-error'] : ''}`;

	return (
		<div className={containerClass}>
			<label className={styles['label']} tabIndex={0}>
				{handler.label}
			</label>
			<div className={styles['input-container']}>
				<input
					style={{ outline: 'none' }}
					{...rest}
					readOnly={handler.disabled}
					className={styles['input']}
					placeholder={handler.placeholder}
					maxLength={handler.maxlength}
					onChange={handleOnChange}
					value={handler.mask ? applyMask(handler.mask, handler.value) : handler.value}
					data-mask={handler.mask}
					onKeyDown={handleKeyDown}
					tabIndex={0}
					id={handler.id ? `${handler.id}-input` : 'input'}
					data-testid={handler.id ? `${handler.id}-input` : 'input'}
					type={getInputType(mode)}
				/>
				{!tooltip && icon && (
					<div className={styles['icon-container']}>
						<Icon icon={icon} />
					</div>
				)}
				{tooltip && icon && (
					<div className={styles['icon-container']}>
						<Tooltip
							id={handler.id ? `${handler.id}-tooltip` : 'tooltip'}
							description={tooltip}
							forceShowSide={forceShowSideTooltip}
						>
							<div>
								<Icon icon={icon} customSize />
							</div>
						</Tooltip>
					</div>
				)}
			</div>
		</div>
	);
};
