import { InputHandler } from "../../components/ui/Input/useInput";
import { Strings } from "../constants/Strings";

export const isDateValid = (date: string, handler?: InputHandler<string>) => {
    if (date.length === 0) {
        return true;
    }
    const val = date.replace(/[^0-9]/g, '');
    
    const month = parseInt(val.substring(0, 2), 10);
    const day = parseInt(val.substring(2, 4), 10);
    const year = parseInt(val.substring(4, 8), 10);

    let dt = new Date(year, month - 1, day, 0, 0, 0, 0);

    if ((!isNaN(dt.getTime())) && (dt.getMonth() === month - 1) && (dt.getDate() === day) && (dt.getFullYear() === year))
        return true;

    handler?.setError(Strings.errors.date.invalid);
    return false;
};

export const isEighteenOrOlder = (date: string, handler?: InputHandler<string>) => {
    if (date.length === 0) {
        return true;
    }
    const dt = stringToDate(date)

    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    if (dt <= eighteenYearsAgo)
        return true;

    handler?.setError(Strings.errors.date.underEighteen);
    return false;
};

export const stringToDate = (str: string) => {
    const val = str.replace(/[^0-9]/g, '');
    
    const month = parseInt(val.substring(0, 2), 10);
    const day = parseInt(val.substring(2, 4), 10);
    const year = parseInt(val.substring(4, 8), 10);

    return new Date(year, month - 1, day, 0, 0, 0, 0);
}