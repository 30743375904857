'use client';

import { RefObject, useEffect } from 'react';

export const useTrapFocus = (containerRef: RefObject<HTMLElement>) => {
	const handleFocus = (event: KeyboardEvent) => {
		const focusableElements: NodeListOf<HTMLElement> | undefined = containerRef.current?.querySelectorAll(
			'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
		);

		if (!focusableElements) return;

		const firstElement = focusableElements?.[0];
		const lastElement = focusableElements?.[focusableElements?.length - 1];

		let isTab = event.key === 'Tab';

		if (!isTab) return;

		if (event.shiftKey) {
			if (document.activeElement === firstElement) {
				lastElement.focus();
				event.preventDefault();
			}
		} else {
			if (document.activeElement === lastElement) {
				firstElement.focus();
				event.preventDefault();
			}
		}
	};

	useEffect(() => {
		containerRef.current?.addEventListener('keydown', handleFocus);
		return () => {
			containerRef.current?.removeEventListener('keydown', handleFocus);
		};
	}, []);
};
