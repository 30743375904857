import { InputHandler } from '../../components/ui/Input/useInput';
import { Strings } from '../constants/Strings';

export const isEmailValid = (email: string): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const validateEmail = (email: string, handler: InputHandler<string>) => {
	if (isEmailValid(email)) {
		handler.setSuccess(Strings.success.greatLetsContinue);
		return true;
	}
	handler.setError(Strings.errors.general.incorrectFormatting);
	return false;
};
