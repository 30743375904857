import { InputHandler } from '../../components/ui/Input/useInput';
import { Strings } from '../constants/Strings';
import { USAreaCodes } from '../constants/USAreaCodes';

export const validatePhone = (phone: string, handler: InputHandler<string>) => {
	const val = phone.replace(/[^0-9]/g, '');
	const areaCode = val.substring(0, 3);
	const isPhoneEmpty = val.length === 0;
	const isPhoneValid = val.length === 10 && USAreaCodes.includes(areaCode);

	if (!isPhoneEmpty && isPhoneValid) {
		handler.setSuccess(Strings.success.greatLetsContinue);
		return true;
	}
	handler.setError(isPhoneEmpty ? Strings.errors.phoneNumber.empty : Strings.errors.phoneNumber.invalid);
	return false;
};
