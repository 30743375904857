import { useState, useEffect } from 'react';

export const useIsMobile = (threshold: number = 768) => {
	const [isMobile, setIsMobile] = useState<boolean>(typeof window !== 'undefined' ? window.innerWidth <= threshold : false);

	useEffect(() => {
		const handleResize = () => {
			if (typeof window !== 'undefined') {
				const _isMobile = window.innerWidth <= threshold;
				if (_isMobile !== isMobile) {
					setIsMobile(_isMobile);
				}
			}
		};

		if (typeof window !== 'undefined') {
			handleResize();
			window.addEventListener('resize', handleResize);
		}

		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('resize', handleResize);
			}
		};
	}, [isMobile]);

	return isMobile;
};