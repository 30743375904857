import type { FC, ChangeEvent, HTMLProps, FocusEvent } from 'react';
import { useState } from 'react';
import { InputHandler } from './useInput';
import { Icon } from '../Icon';

import styles from './InputFloating.module.scss';

export enum InputFloatingModes {
	Text = 'text',
	Decimal = 'decimal',
	Numeric = 'numeric',
	Tel = 'tel',
	Search = 'search',
	Email = 'email',
	Url = 'url',
	None = 'none',
}

interface InputFloatingProps extends Omit<HTMLProps<HTMLInputElement>, 'value'> {
	handler: InputHandler<string>;
	label: string;
	loading?: boolean;
	icon?: JSX.Element;
	labelClassName?: string;
	id?: string;
}
export const InputFloating: FC<InputFloatingProps> = (props) => {
	const { handler, label, loading, icon, labelClassName, id, ...rest } = props;
	const [hasFocus, setHasFocus] = useState<boolean>(false);

	const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
		if (loading) return;

		handler.setValue(ev.currentTarget.value);
		handler.setError('');
		rest.onChange?.(ev);
	};

	const handleOnFocus = (ev: FocusEvent<HTMLInputElement>) => {
		setHasFocus(true);
		rest.onFocus?.(ev);
	};

	const handleOnBlur = (ev: FocusEvent<HTMLInputElement>) => {
		setHasFocus(false);
		rest.onBlur?.(ev);
	};

	const floatingLabel = hasFocus || !!handler.value;
	const _class = `
        ${styles['container']}
        ${floatingLabel ? styles['floating-label'] : ''}
        ${!!handler.error ? styles['has-error'] : ''}
    `;
	return (
		<div className={_class}>
			<div className={styles['top']}>
				<label className={labelClassName} htmlFor={id}>
					{label}
				</label>

				<input
					{...rest}
					id={id}
					value={handler.value}
					onChange={handleOnChange}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
				/>

				{icon && (
					<div className={styles['icon']}>
						<Icon icon={icon} />
					</div>
				)}
			</div>

			<div className={styles['bottom']}>
				{!!handler.error && <div className={styles['error']}>{handler.error}</div>}
			</div>
		</div>
	);
};
