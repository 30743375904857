export const Strings = {
	contactInfo: {
		supportEmail: 'dinerewards@bloominbrands.com',
		supportPhone: '877-546-7407',
	},
	pageTitle: {
		dashboard: 'Your Dashboard | Dine Rewards',
	},
	errors: {
		phoneNumber: {
			invalid:
				"You've entered an invalid phone number. Please enter your ten-digit phone number, including the area code.",
			notRegistered:
				'That phone number is not associated with a Dine Rewards account. Please enter a different phone number or sign in.',
			alreadyInUse:
				'This phone number is already associated with a Dine Rewards account. Please enter a different number or contact 877-546-7407 for support',
			empty: 'This number is required and how you will identify yourself during your meal.',
		},
		email: {
			alreadyInUse:
				'That email is already associated with a Dine Rewards account. Please enter a different email or contact 877-546-7407 for support',
		},
		password: {
			doesNotMatch: 'Please ensure the password and the confirmation are the same',
			rules: [
				'Must have:',
				'• At least 8 characters',
				'• At least 3 of these types of characters:',
				'• Lowercase letters (a-z)',
				'• Uppercase letters (A-Z)',
				'• Numbers (0-9)',
				'• Special characters (e.g. !@#$%¨&*)',
			],
		},
		date: {
			invalid: 'Please enter a valid date',
			underEighteen: 'You must be over 18',
		},
		name: {
			invalid: 'Please enter a valid name',
			tooShort: 'Please type at least 3 characters',
		},
		zipCode: {
			invalid: 'Please enter a valid zip code',
		},
		general: {
			incorrectFormatting: 'Incorrect Formatting',
		},
	},
	success: {
		greatLetsContinue: `Great! Let's continue`,
		changesSaved: 'Changes saved successfully',
	},
	tooltips: {
		birthday: 'We ask for this information to offer special gifts and promos in your birthday.',
		zipCode: 'We will use this information to offer you local promos and gifts.',
		tooltipDescription:
			"You'll use this number at our restaurants to identify yourself as a member, no worries we won't call you.",
	},
	memberProfile: {
		sectionTitles: {
			accountInfo: 'ACCOUNT INFO',
			setPassword: 'SET A NEW PASSWORD',
			personalInfo: 'PERSONAL INFO',
			enterYourBirthday: 'ENTER YOUR BIRTHDAY(MM/DD/YYYY)',
		},
		formButtons: {
			save: 'SAVE CHANGES',
			exit: 'EXIT WITHOUT SAVING',
		},
	},
	modalError: {
		id: 'error-modal',
		title: 'Connection Error',
		htmlString: `We're currently experiencing issues connecting to our servers. Please try again later.
				<br/>
				<br/>
				If the issue keeps happening, contact us via email: <strong>dinerewards@bloominbrands.com</strong>`,
	}
};
